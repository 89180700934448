import { EditOutlined } from '@ant-design/icons';
import {
  Button, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusPromotionLabel } from 'common/components/StatusLabel';
import { EPackageFilter } from 'common/services/extends/package/types';
import { getAllPromotionService } from 'common/services/extends/promotions';
import { EPromotionTypes, EPromotionUserGroups, TPromotion } from 'common/services/extends/promotions/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type TableDataTypes = TPromotion['promotionProgramData'];
interface IPromotionProps {
  roleIndex: boolean;
  isInactiveRecords?: boolean;
}
const PromotionTable: React.FC<IPromotionProps> = ({
  roleIndex, isInactiveRecords
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const queryKey = [`${isInactiveRecords ? 'inactive-promotion-list' : 'promotion-list'}`, keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getAllPromotionService({
      keyword,
      page: currentPage,
      limit: currentView,
      status: isInactiveRecords ? EPackageFilter.INACTIVE : EPackageFilter.REMAIN,
      ...returnParamsFilter
    }),
    {
      keepPreviousData: true,
    }
  );

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      ...val.promotionProgramData
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name?.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    // --- Price
    {
      title: t('system.type'),
      dataIndex: 'type',
      key: 'type',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {EPromotionTypes[data.type]}
        </Typography.Text>
      ),
    },
    // --- user group
    {
      title: t('promotion.userGroup'),
      dataIndex: 'userGroup',
      key: 'userGroup',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {EPromotionUserGroups[data.userGroup]}
        </Typography.Text>
      ),
    },
    // --- isCumulative
    {
      title: t('promotion.isCumulative'),
      dataIndex: 'isCumulative',
      key: 'isCumulative',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.isCumulative ? t('promotion.isCumulativeYes') : t('promotion.isCumulativeNo')}
        </Typography.Text>
      ),
    },
    {
      title: t('promotion.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.startDate);
          const bDate = new Date(b.startDate);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.startDate)}
        </Typography.Text>
      ),
    },
    {
      title: t('promotion.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.endDate);
          const bDate = new Date(b.endDate);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.endDate)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: TableDataTypes) => (
        <StatusPromotionLabel status={data.status} />
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.createdAt || '');
          const bDate = new Date(b.createdAt || '');
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.updatedAt || '');
          const bDate = new Date(b.updatedAt || '');
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EditOutlined />}
            onClick={() => roleIndex && navigate(`${ROUTE_PATHS.PROMOTION_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('promotions', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams(mappingFilterToQuery(selectedFilterList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  /* Render */
  return (

    <PageTable
      noCheckbox
      handleSearch={setKeyword}
      isLoading={isLoading}
      roles={{
        roleCreate: false,
        roleDelete: false,
        roleUpdate: false
      }}
      tableProps={{
        initShowColumns: ['id', 'name', 'type', 'userGroup', 'isCumulative', 'startDate', 'endDate', 'status', 'createdAt', 'updatedAt', 'action'],
        columns,
        pageData: tableData || [],
        currentPage,
        pageSize: currentView,
        handleSetCurrentPage,
        handleSetCurrentView,
        total: listRes?.meta.total || 1,
        noBaseCol: true,
        filterFields,
      }}
      filtersDataTable={{
        handleFilter,
        selectedFilterList,
        handleDeleteFilter
      }}
    />
  );
};

export default PromotionTable;
