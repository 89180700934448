import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography, message, Card, Button, Upload, Space, DatePicker
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React, {
  useEffect, useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import Editor from 'common/components/Editor';
import Input from 'common/components/Input';
import SelectFile from 'common/components/SelectFile';
import { getSystemGeneralService, updateSystemGeneralService } from 'common/services/systems';
import { UpdateSystemGeneralParams } from 'common/services/systems/types';
import { getImageURL } from 'common/utils/functions';
import { generalSchemas } from 'common/utils/schemas';

type SystemGeneralForm = {
  gaIds: string;
  gtmIds: string;
  gMapId: string;
  fileFavicon?: File;
  unitPrice?: number;
  unitPriceDuration?: number;
  maxDuration?: number;
  tax?: number;
  exchangeRate?: number;
  idFanpageFB?: string;
  idZalo?: string;
  promotionRange?: string[];
  discountPercent?: number;
  civitaiWelcomeFavicon?: string;
  civitaiWelcomeContent?: string;
};

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const GeneralSM: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  /* React-hooks-form */
  const method = useForm<SystemGeneralForm>({
    resolver: yupResolver(generalSchemas),
    mode: 'onChange',
    defaultValues: {
      gaIds: '',
      gtmIds: '',
      gMapId: '',
      fileFavicon: undefined,
      promotionRange: undefined,
      discountPercent: undefined,
      unitPrice: 0,
      unitPriceDuration: 0,
      maxDuration: 0,
      tax: 0,
      idFanpageFB: '',
      idZalo: '',
      civitaiWelcomeContent: '',
      civitaiWelcomeFavicon: undefined
    },
  });

  /* Queries */
  const {
    data: generalData,
  } = useQuery(
    ['systemsManagement-general', 'list'],
    () => getSystemGeneralService(),
  );

  const { mutate: editGeneralMutate, isLoading: isEditLoading } = useMutation(
    ['systemsManagement-general', 'edit'],
    async (params: UpdateSystemGeneralParams) => updateSystemGeneralService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: () => {
        message.error(t('message.updateError'));
      }

    }
  );

  /* Functions */
  const handleSubmit = (data: SystemGeneralForm) => {
    const submitData = {
      ...data,
      promotionStart: moment(data.promotionRange
        ? data.promotionRange[0] : new Date()).toISOString(),
      promotionEnd: moment(data.promotionRange
        ? data.promotionRange[1] : new Date()).toISOString(),
      discountPercent: Number(data?.discountPercent || ''),
    };
    editGeneralMutate(submitData);
  };

  /* Effects */
  useEffect(() => {
    if (generalData) {
      method.reset({
        gaIds: generalData.gaIds?.toString(),
        gtmIds: generalData.gtmIds?.toString(),
        gMapId: generalData.gMapId,
        unitPrice: generalData.unitPrice,
        unitPriceDuration: generalData.unitPriceDuration,
        maxDuration: generalData.maxDuration,
        tax: generalData.tax,
        exchangeRate: generalData.exchangeRate,
        idFanpageFB: generalData.idFanpageFB,
        idZalo: generalData.idZalo,
        promotionRange: [generalData.promotionStart, generalData.promotionEnd],
        discountPercent: generalData.discountPercent,
        civitaiWelcomeContent: generalData.civitaiWelcomeContent,
        civitaiWelcomeFavicon: generalData.civitaiWelcomeFavicon
      });
      setFileList([{
        uid: '-1',
        name: generalData?.favicon?.split('/').slice(-1)[0] || 'favicon.ico',
        url: getImageURL(generalData?.favicon),
        thumbUrl: getImageURL(generalData?.favicon),
      }]);
    }
  }, [generalData, method]);

  return (
    <div className="p-system_general">
      <FormProvider<SystemGeneralForm> {...method}>
        <form noValidate>
          <Card type="inner">
            <Space className="p-system_general_space" direction="vertical" size={16}>
              <Controller
                name="gaIds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <div>
                    <Typography.Text strong>
                      {t('systemManagement.gaId')}
                      {' '}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </div>
                )}
              />

              <Controller
                name="gtmIds"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.gtmId')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="gMapId"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.gMapId')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="unitPrice"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.unitPrice')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      type="number"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="unitPriceDuration"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.unitPriceDuration')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      type="number"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />
              <Controller
                name="maxDuration"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.maxDuration')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      type="number"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="exchangeRate"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.exchangeRate')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      type="number"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="tax"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.tax')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      type="number"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="idFanpageFB"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.idFanpageFB')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="idZalo"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.idZalo')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="fileFavicon"
                render={({ field: { onChange } }) => (
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(info) => {
                      onChange(info.fileList?.[0]?.originFileObj);
                      setFileList([...info.fileList]);
                    }}
                    onRemove={() => onChange(undefined)}
                    maxCount={1}
                    fileList={fileList}
                    accept="image/x-icon"
                  >
                    <Button
                      type="default"
                    >
                      {t('systemManagement.uploadFavicon')}
                    </Button>
                  </Upload>
                )}
              />

              <Typography.Title level={4}>
                {t('systemManagement.promotional')}
              </Typography.Title>

              <Controller
                name="promotionRange"
                control={method.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.promotionalRangeTime')}
                    </Typography.Text>
                    <RangePicker
                      className="u-mt-8"
                      allowClear
                      size="large"
                      value={value ? [moment(value[0]), moment(value[1])] : undefined}
                      format={dateFormat}
                      onChange={(values) => values && onChange(values.map(
                        (item) => moment(item).toISOString()
                      ))}
                      style={{ width: '100%', display: 'flex' }}
                    />
                  </>
                )}
              />

              <Controller
                name="discountPercent"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.promotionalPriceUnit')}
                    </Typography.Text>
                    <Input
                      type="number"
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="civitaiWelcomeFavicon"
                render={({ field: { value, onChange } }) => (
                  <SelectFile
                    value={value}
                    name="civitaiWelcomeFavicon"
                    handleSelect={(url) => onChange(url)}
                    handleDelete={() => onChange(undefined)}
                    title={t('aiServer.civitaiWelcomeFavicon')}
                  />
                )}
              />

              <Controller
                name="civitaiWelcomeContent"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('systemManagement.civitaiWelcomeContent"')}
                    </Typography.Text>
                    <Editor
                      value={value}
                      handleChange={(_event: any, editor: any) => {
                        const data = editor.getData();
                        onChange(data);
                      }}
                    />
                    {error && <div className="u-mt-8 u-text-red-500">{error?.message}</div>}
                  </>
                )}
              />
            </Space>
            <div className="p-system_general_submit u-mt-16">
              <Button
                type="primary"
                disabled={!canEdit}
                loading={isEditLoading}
                onClick={method.handleSubmit(handleSubmit)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            </div>
          </Card>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralSM;
