/* eslint-disable prefer-regex-literals */
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { EPromotionTypes, EPromotionUserGroups, TPromotionRequest } from 'common/services/extends/promotions/types';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

type TTestContext<T> = yup.TestContext
  & {
    from: Array<{
      schema: yup.ObjectSchema<any>,
      value: T
    }>
  };

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Địa chỉ email là bắt buộc'),
  password: yup.string().required('Mật khẩu là bắt buộc'),
  otpCode: yup.string().matches(new RegExp('^[0-9]+$'), { message: 'OTP phải là số' }).length(6, 'OTP phải có 6 chữ số')
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Địa chỉ email là bắt buộc'),
});

export const detailPageSchema = yup.object().shape({
  title: yup.string().required('Trường này là bắt buộc'),
  slug: yup.string().required('Trường này là bắt buộc'),
  seoTitle: yup.string(),
});

export const menuGenerationForm = yup.object().shape({
  title: yup.number().required('Trường này là bắt buộc'),
  target: yup.number().required('Trường này là bắt buộc'),
});

export const menuGenerationForm2 = yup.object().shape({
  title: yup.string().required('Trường này là bắt buộc'),
  target: yup.number().required('Trường này là bắt buộc'),
});

export const detailPageHeaderSchema = yup.object().shape({
  sample: yup.string().required('Hãy chọn một mẫu'),
  parent: yup.number(),
  state: yup.number(),
  isHome: yup.boolean(),
});

const blockSectionObj = {
  name: yup.string(),
  description: yup.string(),
  content: yup.string(),
  ogImage: yup.object().nullable(),
  link: yup.string(),
  url: yup.string(),
  target: yup.object().nullable(),
};

const mediaSocialObj = {
  ogTitle: yup.string(),
  ogDescription: yup.string(),
  ogType: yup.number().required('Chọn 1 mạng xã hội'),
  ogImage: yup.object().nullable(),
};

export const seoSectionSchema = yup.object().shape({
  seoTitle: yup.string(),
  seoIntro: yup.string(),
  seoKeyword: yup.string(),
  metaViewPort: yup.string(),
  metaRobot: yup.string(),
  canonicalURL: yup.string(),
  structuredData: yup.string(),
  ogImage: yup.object().nullable(),
  mediaSocial: yup
    .array()
    .of(yup.object().shape(mediaSocialObj)),
});

export const openGraphSectionSchema = yup.object().shape({
  ogTitle: yup.string(),
  ogType: yup.string(),
  ogImage: yup.object().nullable(),
});

export const blockSectionSchema = yup.object().shape({
  sectionTitle: yup.string(),
  blockSection: yup
    .array()
    .of(yup.object().shape(blockSectionObj)),
});

export const commentSectionSchema = yup.object().shape({
  comment: yup.string(),
});

export const menuCreateSchema = yup.object().shape({
  title: yup.string().required('Điền tiêu đề menu'),
  code: yup.string().required('Điền mã code cho menu'),
});

export const templateEditSchema = yup.object().shape({
  title: yup.string().required('Điền tiêu đề trang mẫu'),
});

export const bannerCreateSchema = yup.object().shape({
  name: yup.string().required('Điền tên banner'),
  items: yup.array().of(
    yup.object().shape({
      type: yup.string(),
      data: yup.object().when(
        'type',
        {
          is: 'video',
          then: (schema) => schema.shape({
            videoType: yup.string().required('Vui lòng chọn loại'),
            videoUrl: yup.string().required('Thông tin bắt buộc'),
            videoThumbnail: yup.string().required('Thông tin bắt buộc')
          })
        }
      )
    })
  )
});

export const pageDetailMainData = yup.object().shape({
  title: yup.string().required('Điền tiêu đề trang'),
  slug: yup.string().required('Điền đường dẫn trang'),
});

export const redirectFormSchema = yup.object().shape({
  from: yup.string().required('Thông tin bắt buộc'),
  to: yup.string().required('Thông tin bắt buộc'),
});

export const roleFormSchema = yup.object().shape({
  displayName: yup
    .string()
    .required('Thông tin bắt buộc'),
});

export const userFormSchema = yup.object().shape({
  name: yup.string().required('Thông tin bắt buộc'),
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Địa chỉ email là bắt buộc'),
  password: yup.string().notRequired().test('password', 'Mật khẩu ít nhất 6 kí tự', (value) => {
    if (value) {
      const schema = yup.string().min(6);
      return schema.isValidSync(value);
    }
    return true;
  }),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Mật khẩu xác nhận không khớp'),
  roles: yup.array().min(1, 'Chọn ít nhất 1 quyền')
});

export const updateNewsByIdSchema = yup.object().shape({
  displayOrder: yup.number().required('Nhập thứ tự hiển thị'),
  title: yup.string().required('Nhập tiêu đề'),
  slug: yup.string().required('Nhập đường dẫn'),
  description: yup.string().required('Nhập mô tả'),
  content: yup.string().required('Nhập nội dung'),
  thumbnail: yup.string().required('Cập nhật hình ảnh'),
});

export const updateNewsCategoriesByIdSchema = yup.object().shape({
  displayOrder: yup.number().required('Nhập thứ tự hiển thị'),
  name: yup.string().required('Nhập tiêu đề'),
  slug: yup.string().required('Nhập đường dẫn'),
  description: yup.string().required('Nhập mô tả'),
});

export const updateCategoryFaqByIdSchema = yup.object().shape({
  displayOrder: yup.number().required('Nhập thứ tự hiển thị'),
  name: yup.string().required('Nhập tiêu đề'),
  slug: yup.string().required('Nhập đường dẫn'),
  description: yup.string().required('Nhập mô tả'),
});

export const updateFaqByIdSchema = yup.object().shape({
  answer: yup.string().required('Nhập câu hỏi'),
  question: yup.string().required('Nhập câu trả lời'),
  faqCategoryId: yup.array().required('Chọn danh mục'),
});

export const updateContactProblemSchema = yup.object().shape({
  name: yup.string().required('Nhập tên'),
});

export const updateProfileSchema = yup.object().shape({
  name: yup.string().required('Trường này là bắt buộc'),
  email: yup.string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Trường này là bắt buộc'),
  password: yup.string().required('Trường này là bắt buộc'),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Trường này là bắt buộc'),
  newPassword: yup.string().min(6).required('Trường này là bắt buộc').notOneOf([yup.ref('oldPassword')], 'Mật khẩu mới không được trùng mật khẩu cũ')
    .min(6, 'Mật khẩu phải ít nhất 6 kí tự'),
  newPasswordConfirm: yup.string().required('Trường này là bắt buộc').oneOf([yup.ref('newPassword')], 'Mật khẩu xác nhận không khớp'),
});

export const updateEmailTemplateSchema = yup.object().shape({
  name: yup.string().required('Nhập tên'),
  subject: yup.string().required('Nhập tiêu đề'),
  content: yup.string().required('Nhập nội dung'),
});

export const activeTOtpSecretSchema = yup.object().shape({
  currentPassword: yup.string().required('Trường này là bắt buộc'),
  otpCode: yup.string().matches(new RegExp('^[0-9]+$'), { message: 'OTP phải là số' }).length(6, 'OTP phải có 6 chữ số').required('Trường này là bắt buộc')
});

export const requiredPasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Trường này là bắt buộc'),
});

export const staticBlocksMainData = yup.object().shape({
  name: yup.string().required('Trường này là bắt buộc'),
  templateCode: yup.string().required('Trường này là bắt buộc'),
});

export const generalSchemas = yup.object().shape({
  discountPercent: yup
    .number()
    .typeError('Vui lòng nhập thông tin')
    .min(0, 'Đơn vị thời lượng khuyến mãi phải lớn hơn hoặc bằng 0')
    .max(100, 'Đơn vị thời lượng khuyến mãi phải nhỏ hơn hoặc bằng 100')
    .required('Trường này là bắt buộc')
    .test('decimalFormat', 'Đơn vị thời lượng khuyến mãi phải là số nguyên', (value) => {
      const decimalRegex = /^[0-9]*$/;
      return decimalRegex.test(value?.toString() || '');
    }),
  unitPriceDuration: yup
    .number()
    .typeError('Vui lòng nhập thông tin')
    .min(1, 'Đơn vị giá thời lượng phải lớn hơn 0')
    .max(yup.ref('maxDuration'), 'Đơn vị giá thời lượng phải nhỏ hơn thời lượng tối đa'),
  maxDuration: yup
    .number()
    .typeError('Vui lòng nhập thông tin')
    .min(yup.ref('unitPriceDuration'), 'Thời lượng tối đa phải lớn hơn đơn vị giá thời lượng'),
  exchangeRate: yup
    .number()
    .typeError('Vui lòng nhập thông tin')
    .min(1000, 'Tỉ giá phải lớn hơn 1000'),
});

export const useSchema = () => {
  const { t } = useTranslation();

  const customerDataSchema = yup.object().shape({
    phone: yup.string().typeError(t('formManagement.requiredFormField')).matches(phoneRegExp, 'Số điện thoại không hợp lệ').required(t('formManagement.requiredFormField')),
    name: yup.string().typeError('Tên không hợp lệ').required(t('formManagement.requiredFormField')),
    address: yup.string().typeError('Địa chỉ không hợp lệ').required(t('formManagement.requiredFormField')),
    birthYear: yup.number().typeError('Năm sinh không hợp lệ').required(t('formManagement.requiredFormField')),
    countryCode: yup.object().typeError(t('formManagement.requiredFormField')).required(t('formManagement.requiredFormField')),
    provinceCode: yup.object().typeError(t('formManagement.requiredFormField')).required(t('formManagement.requiredFormField')),
    gender: yup.number().typeError(t('formManagement.requiredFormField')).required(t('formManagement.requiredFormField')),
  });

  const orderDataSchema = yup.object().shape({
    id: yup.string().required(t('system.required')),
    unitPrice: yup.string().required(t('system.required')),
    unitPriceDuration: yup.string().required(t('system.required')),
    tax: yup.string().required(t('system.required')),
    customerName: yup.string().required(t('system.required')),
    customerEmail: yup.string().required(t('system.required')),
    customerPhone: yup.string().required(t('system.required')),
    usageTime: yup.string().required(t('system.required')),
    status: yup.string().required(t('system.required')),
    dateBilling: yup.string().required(t('system.required')),
    createdAt: yup.string().required(t('system.required')),
    updatedAt: yup.string().required(t('system.required')),
  });

  const packageSchema = yup.object().shape({
    displayOrder: yup.number().required('Nhập thứ tự hiển thị').typeError('Nhập thứ tự hiển thị'),
    name: yup.string().required(t('system.required')),
    price: yup.number().required(t('system.required')),
    accessTime: yup.number().required(t('system.required')),
    validityPeriod: yup.number().required(t('system.required')),
    startDate: yup.string().required(t('system.required'))
  });

  const packageDefaultSchema = yup.object().shape({
    displayOrder: yup.number().required('Nhập thứ tự hiển thị').typeError('Nhập thứ tự hiển thị'),
    name: yup.string().required(t('system.required')),
    price: yup.number().required(t('system.required')),
    accessTime: yup.number().required(t('system.required')),
    validityPeriod: yup.number().required(t('system.required')),
    startDate: yup.string().required(t('system.required'))
  });

  const promotionSchema = yup.object().shape({
    name: yup.string().required(t('system.required')),
    type: yup.string().required(t('system.required')),
    userGroup: yup.string().required('system,required'),
    customerIds: yup.array().of(yup.number()).test({
      name: 'required customerIds',
      message: t('system.required'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const { userGroup } = from[0].value;
        const { customerIds } = from[0].value;
        return !(Number(userGroup) === EPromotionUserGroups.Specific
          && customerIds.length === 0);
      }
    }),
    totalCodes: yup.number().test({
      name: 'greater than 0',
      message: t('message.promotionMinTotalCodes'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const userGroupValue = from[0].value.userGroup;
        const { totalCodes } = from[0].value;
        return !(Number(userGroupValue) === EPromotionUserGroups.All
          && totalCodes <= 0);
      }
    }).test({
      name: 'greater than or equal 0',
      message: t('message.valueMinEqual'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const { totalCodes } = from[0].value;
        return !(totalCodes < 0);
      }
    }),
    buyAmountX: yup.number().test({
      name: 'greater than 0',
      message: t('message.valueMin'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const typeValue = from[0].value.type;
        const { buyAmountX } = from[0].value;
        return !(Number(typeValue) === EPromotionTypes.BuyXGetY
          && buyAmountX < 1);
      }
    }).test({
      name: 'is cumulative value',
      message: t('message.isCumulativeValue'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const { isCumulative } = from[0].value;
        const { buyAmountX } = from[0].value;
        const { freeAmountY } = from[0].value;
        return !(isCumulative && (freeAmountY !== buyAmountX));
      }
    }),
    freeAmountY: yup.number().test({
      name: 'greater than 0',
      message: t('message.valueMin'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const typeValue = from[0].value.type;
        const { freeAmountY } = from[0].value;
        return !(Number(typeValue) === EPromotionTypes.BuyXGetY
          && freeAmountY < 1);
      }
    }).test({
      name: 'is cumulative value',
      message: t('message.isCumulativeValue'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const { isCumulative } = from[0].value;
        const { buyAmountX } = from[0].value;
        const { freeAmountY } = from[0].value;
        return !(isCumulative && (freeAmountY !== buyAmountX));
      }
    }),
    xHoursRate: yup.number().test({
      name: 'greater than 1',
      message: t('message.valueMin'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const typeValue = from[0].value.type;
        const { xHoursRate } = from[0].value;
        return !(typeValue === EPromotionTypes.XHours && !!xHoursRate && xHoursRate < 1);
      }
    }),
    discountPercentage: yup.number().test({
      name: 'min discount percent',
      message: t('message.promotionMinPercentage'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const typeValue = from[0].value.type;
        const discountPercentValue = from[0].value.discountPercentage;
        return !(Number(typeValue) === EPromotionTypes.DiscountCode
          && discountPercentValue < 1);
      }
    }).test({
      name: 'max discount percent',
      message: t('message.promotionMaxPercentage'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const typeValue = from[0].value.type;
        const discountPercentValue = from[0].value.discountPercentage;
        return !(Number(typeValue) === EPromotionTypes.DiscountCode
          && discountPercentValue > 100);
      }
    }),
    discountCode: yup.string().test({
      name: 'required discount code',
      message: t('system.required'),
      test: (_, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const { userGroup } = from[0].value;
        const { discountCode } = from[0].value;
        return !(Number(userGroup) === EPromotionUserGroups.All
          && !discountCode);
      }
    }).test({
      name: 'valid length',
      message: t('message.promotionCodeLength'),
      test: (val, context) => {
        const { from } = context as TTestContext<TPromotionRequest>;
        const { userGroup } = from[0].value;
        return !(Number(userGroup) === EPromotionUserGroups.All && !!(val && val.length > 11));
      }
    }),
    startDate: yup.string().required(t('system.required'))
  });

  return {
    customerDataSchema,
    orderDataSchema,
    packageSchema,
    packageDefaultSchema,
    promotionSchema
  };
};

export const ModuleAIServerDetailSchema = yup.object().shape({
  name: yup.string().required('vui lòng nhâp tên'),
});
