export enum EPromotionTypes {
  BuyXGetY = 1,
  XHours = 2,
  DiscountCode = 3
}

export enum EPromotionUserGroups {
  All = 0,
  Specific = 1
}

export enum EPromotionStatus {
  New = 1,
  Waiting = 7,
  Active = 13,
  Inactive = 21
}
export enum EPromotionCodeStatus {
  Used = 1,
  NotUseYet = 0
}
export type TPromotion = {
  promotionProgramData: {
    id: number,
    name: string,
    type: EPromotionTypes,
    startDate: string,
    endDate: string,
    userGroup: EPromotionUserGroups,
    totalCodes: number,
    status: EPromotionStatus,
    buyAmountX?: number,
    freeAmountY?: number,
    isCumulative: boolean,
    xHoursRate: number,
    customerIds: number[],
    discountCode?: string,
    discountPercentage: number,
    description?: string,
    createdAt?: string,
    updatedAt?: string
  },
  updater: CreatorType,
  creator: CreatorType
};

export type TPromotionRequest = {
  name: string,
  type: EPromotionTypes,
  description: string,
  startDate: string,
  endDate: string | null,
  userGroup: EPromotionUserGroups,
  customerIds: number[],
  totalCodes: number,
  buyAmountX: number,
  freeAmountY: number,
  isCumulative: boolean,
  xHoursRate?: number,
  discountCode?: string,
  discountPercentage: number
};

export type TPromotionCode = {
  promotionProgramCodeData: {
    id: number
    promotionProgramCode: string
    customer: {
      name: string
      email: string
    }
    status: EPromotionCodeStatus
    redeemedDate: string
    isSentMail: boolean
  }
};
