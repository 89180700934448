import { EditOutlined } from '@ant-design/icons';
import {
  Button, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusPackageLabel } from 'common/components/StatusLabel';
import { getAllPackageService } from 'common/services/extends/package';
import { EPackageFilter, EPackageType, TPackage } from 'common/services/extends/package/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';

export type TableDataTypes = TPackage['packageData'];
interface ITableProps {
  roleIndex: boolean
  isInactiveRecord?: boolean
}

const PackageTable: React.FC<ITableProps> = ({
  roleIndex, isInactiveRecord
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  /* Selectors */
  const {
    defaultPageSize,
    advancedFilter
  } = useAppSelector((state) => state.system);
  // const { roles: role } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const queryKey = [`${isInactiveRecord ? 'inactive-package-list' : 'package-list'}`, keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getAllPackageService({
      keyword,
      page: currentPage,
      limit: currentView,
      status: isInactiveRecord ? EPackageFilter.INACTIVE : EPackageFilter.REMAIN,
      ...returnParamsFilter
    }),
    {
      keepPreviousData: true
    }
  );

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      ...val.packageData
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name?.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.CUSTOMER_DETAIL}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    // --- Access time
    {
      title: t('package.accessTime'),
      dataIndex: 'accessTime',
      key: 'accessTime',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {`${data.accessTime} ${data.accessTime > 1 ? t('package.minutes') : t('package.minute')}`}
        </Typography.Text>
      ),
    },
    // --- validityPeriod time
    {
      title: t('package.validityPeriod'),
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {`${data.validityPeriod} ${data.validityPeriod > 1 ? t('package.months') : t('package.month')}`}
        </Typography.Text>
      ),
    },
    // --- Price
    {
      title: t('package.price'),
      dataIndex: 'price',
      key: 'price',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.price)}
        </Typography.Text>
      ),
    },
    // --- Price
    {
      title: t('system.type'),
      dataIndex: 'type',
      key: 'type',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text>
          {EPackageType[data.type]}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: TableDataTypes) => (
        <StatusPackageLabel status={data.status} />
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EditOutlined />}
            onClick={() => roleIndex && navigate(`${ROUTE_PATHS.PACKAGE_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('packages', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams(mappingFilterToQuery(selectedFilterList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  /* Render */
  return (
    <PageTable
      noCheckbox
      handleSearch={setKeyword}
      isLoading={isLoading}
      roles={{
        roleCreate: false,
        roleDelete: false,
        roleUpdate: false
      }}
      tableProps={{
        initShowColumns: ['id', 'name', 'accessTime', 'validityPeriod', 'price', 'type', 'status', 'createdAt', 'updatedAt', 'action'],
        columns,
        pageData: tableData || [],
        currentPage,
        pageSize: currentView,
        handleSetCurrentPage,
        handleSetCurrentView,
        total: listRes?.meta.total || 1,
        noBaseCol: true,
        filterFields,
      }}
      filtersDataTable={{
        handleFilter,
        selectedFilterList,
        handleDeleteFilter
      }}
    />
  );
};

export default PackageTable;
